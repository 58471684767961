@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

@layer base {
  /* @font-face {
    font-family: 'Sarabun';
    src: url(../../public/fonts/Sarabun-Regular.ttf);
  }
  @font-face {
    font-family: 'Sarabun';
    font-weight: 500;
    src: url(../../public/fonts/Sarabun-Medium.ttf);
  }
  @font-face {
    font-family: 'Sarabun';
    font-weight: 600;
    src: url(../../public/fonts/Sarabun-SemiBold.ttf);
  }
  @font-face {
    font-family: 'Sarabun';
    font-weight: 700;
    src: url(../../public/fonts/Sarabun-Bold.ttf);
  } */
  html,
  body {
    @apply bg-white;
    @apply text-content-t100;
  }

  /* Disable the buttons in the input type="number" */
  /* For Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

a {
  color: inherit;
  text-decoration: none;
  word-break: break-word;
}

#nprogress .bar {
  background: #1fc0f4 !important;
  height: 3px !important;
  z-index: 1101 !important;
}

* {
  box-sizing: border-box;
}

/* .d-input input:focus ~ span,
.d-input input:not(:placeholder-shown) ~ span {
  @apply transform -translate-y-1.5 ml-0 text-s;
}
.d-input input:not(:placeholder-shown) {
  @apply pt-6 pb-2;
}
*/

.d-phone-input input:focus ~ span,
.d-phone-input input:not(:placeholder-shown) ~ span {
  @apply transform scale-75 -translate-y-2 ml-0 text-s;
}
.d-phone-input input:not(:placeholder-shown) {
  @apply pt-6 pb-2;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@media (max-width: 768px) {
  .intercom-lightweight-app-launcher {
    bottom: 72px !important;
  }

  /*localhost*/
  .intercom-dfosxs {
    bottom: 72px !important;
  }

  iframe[id='intercom-frame'] {
    bottom: 124px !important;
  }
}

.tagify {
  width: 100%;
  max-width: 700px;
}
